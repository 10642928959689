import { GoPlus } from "react-icons/go";
import CountryAccordian from "../components/shared/CountryAccordian";
import { TreatmentContextModel, useTreatment } from "../modules/treatment";
import { useEffect, useState } from "react";
import TreatmentSearchPopup from "../components/shared/TreatmentSearchPopup";
import { useNavigate } from "react-router-dom";
import { CureValueService } from "../../helpers/ServiceWrapper";
import { getAllCities as getAllCitiesService } from "../modules/city";
import {
  City,
  CountryWiseCity,
  RegionWiseCountry,
} from "../modules/city/core/_models";
import _, { forEach } from "lodash";
import { getURLFromString } from "../../helpers/CVHelper";
import Loader from "../components/shared/Loader";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Helmet } from "react-helmet";

const CitySelectionList = () => {
  const { selectedTreatment } = useTreatment();
  const [isTreatmentModelOpen, setIsTreatmentModelOpen] =
    useState<boolean>(false);
  const [regionWiseCountries, setRegionWiseCountries] = useState<
    RegionWiseCountry[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false); // State for the single accordion

  const navigate = useNavigate();

  const handleonClose = (treatment: TreatmentContextModel | null) => {
    if (treatment?.treatment) {
      navigate(
        `/cityselection?service=${getURLFromString(treatment?.treatment)}`
      );
    }
    setIsTreatmentModelOpen(false);
  };

  useEffect(() => {
    if (!selectedTreatment) {
      setIsTreatmentModelOpen(true);
      getAllCities({ treatmentName: null });
    }
    if (selectedTreatment) {
      getAllCities({ treatmentName: selectedTreatment.treatment });
    }
  }, [selectedTreatment]);

  const getAllCities = async (payload: { treatmentName: any }) => {
    const { data, error } = await CureValueService(
      getAllCitiesService,
      payload,
      { setIsLoading }
    );
    if (data) {
      const sortedData: any[] = [];
      const regionGroupedData = _.chain(data)
        .filter((city) => (city.facilities_count ?? 0) > 0)
        .groupBy("regionName")
        .map((value, key) => ({ regionName: key, cities: value }))
        .value();

      regionGroupedData.forEach((regionData) => {
        const countryGroupedData = _.chain(regionData.cities)
          .groupBy("country_name")
          .map((cities, countryName) => {
            const totalFacilityCount = cities.reduce(
              (sum, city) => sum + (city.facilities_count || 0),
              0
            );
            return {
              country_name: countryName,
              total_facility_count: totalFacilityCount,
              cities: cities,
            };
          })
          .value();

        sortedData.push({
          regionName: regionData.regionName,
          countries: [...countryGroupedData],
        });
      });

      setRegionWiseCountries(sortedData);
    }
  };

  const handleCityClick = (city: City) => {
    if (selectedTreatment) {
      navigate(
        `/city/${getURLFromString(
          city.country_name
        ).toLowerCase()}/${getURLFromString(
          city.city_name
        ).toLowerCase()}/${getURLFromString(
          selectedTreatment.treatment
        ).toLowerCase()}`
      );
    }
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  let jsonLD = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "City Selection for Cosmetic Surgery - Cure Value",
    url: "https://curevalue.org/cityselection?service=cosmetic-surgery",
    description:
      "Choose the best city for your cosmetic surgery needs with Cure Value. Find top-rated clinics, compare services, and make an informed decision for your healthcare.",
    mainEntity: {
      "@type": "MedicalOrganization",
      name: "Cure Value",
      url: "https://curevalue.org/",
      logo: "https://curevalue.org/logo.png",
      contactPoint: {
        "@type": "ContactPoint",
        telephone: "+1-800-123-4567",
        contactType: "Customer Service",
        email: "info@curevalue.org",
      },
      address: {
        "@type": "PostalAddress",
        streetAddress: "5678 Health Street",
        addressLocality: "San Francisco",
        addressRegion: "CA",
        postalCode: "94103",
        addressCountry: "USA",
      },
      sameAs: [
        "https://www.facebook.com/curevalue",
        "https://www.instagram.com/curevalue",
        "https://twitter.com/curevalue",
        "https://www.linkedin.com/company/curevalue",
      ],
    },
  };

  return (
    <div className="bg-[#F5F7FA] pt-[70px] lg:pt-[80px] pb-[70px]">
      {/* <Helmet>
        <title>Find the Best City for Dentistry Services | Cure Value</title>
        <meta
          property="og:title"
          content={`City Selection for ${selectedTreatment?.treatment} - Cure Value`}
        />
        <meta
          property="og:description"
          content={`Find the best city for your ${selectedTreatment?.treatment} needs with Cure Value. Compare top-rated clinics, services, and make an informed decision for your dental care.`}
        />
        <meta
          property="og:url"
          content={`https://dev.curevalue.org/cityselection?service=${selectedTreatment?.treatment}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`${window.location.origin}/media/images/CV-Logo.png`}
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`City Selection for ${selectedTreatment?.treatment} - Cure Value`}
        />
        <meta
          name="twitter:description"
          content={`Find the best city for your ${selectedTreatment?.treatment} needs with Cure Value. Compare top-rated clinics, services, and make an informed decision for your dental care.`}
        />
        <meta
          name="twitter:url"
          content={`${window.location.origin}/media/images/CV-Logo.png`}
        />
        <meta
          name="twitter:image"
          content={`${window.location.origin}/media/images/CV-Logo.png`}
        />
        <meta name="twitter:site" content="@CureValue" />
        <meta name="twitter:creator" content="@CureValue" />
        <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
      </Helmet> */}
      {isTreatmentModelOpen && (
        <TreatmentSearchPopup onClose={handleonClose} isClosable={false} />
      )}
      <div className="sm:block lg:block lg:max-w-[1800px] m-auto px-[24px] pt-[30px] pb-[24px]">
        <div className="mt-[20px]">
          <h2 className="text-[24px] lg:text-[26px] font-semibold mb-[11px] pb-5">
            Choose your destination for {selectedTreatment?.treatment}
          </h2>
          <div
            className="accordion-header flex justify-between items-center cursor-pointer p-4 bg-gray-200"
            onClick={toggleAccordion}
          >
            <h2 className="text-xl font-semibold">Regions and Countries</h2>
            {isOpen ? <IoIosArrowUp size={20} /> : <IoIosArrowDown size={20} />}
          </div>
          {isOpen && (
            <div className="accordion-content p-4 flex gap-[50px] justify-center lg:justify-between 2xl:justify-start flex-wrap">
              {regionWiseCountries.map(
                (regionWiseCountry: RegionWiseCountry) => (
                  <div
                    className="flex flex-col gap-[10px] items-left w-[250px]"
                    key={regionWiseCountry.regionName}
                  >
                    <div className="text-h4font font-semibold border-b-pc border-b">
                      {regionWiseCountry.regionName !== "Unknown"
                        ? regionWiseCountry.regionName
                        : "Other"}
                    </div>
                    {regionWiseCountry.countries.map(
                      (countryWiseCity: CountryWiseCity) => (
                        <CountryAccordian
                          {...countryWiseCity}
                          onCityClick={handleCityClick}
                          key={countryWiseCity.country_name}
                        />
                      )
                    )}
                  </div>
                )
              )}
              {isLoading && (
                <div className="w-full">
                  <Loader />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CitySelectionList;
