import { IoStar, IoClose } from "react-icons/io5";
import docimg from "../../../assets/images/doc-img.png";
import { FaHands } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import React, { useState, useEffect, useRef } from "react";
import treatmentColourMap from "../../modules/common/core/treatmentColourMap.json";
import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
} from "react-icons/fa";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import { getById } from "../../modules/doctor/core/doctorService";
import { Doctor } from "../../modules/doctor/core/_models";
import { FaUserDoctor } from "react-icons/fa6";
import CVPopup from "../shared/CVPopup";
import { IoMdLock } from "react-icons/io";
import { useAuth } from "../../modules/auth";
import AppointmentSchedule from "../../pages/AppointmentSchedule"

const CareTeam = ({
  facility,
  setDoctorCount,
  cardFor,
  isBeforeLogin,
  handleShowSignUpPopup,
}: any) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [allDoctors, setAllDoctors] = useState<Doctor[]>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<any>();
  const [selectedTreatment, setSelectedTreatment] = useState<any>(null);
  const totalDoctorRecords = useRef(0);
  const { currentUser } = useAuth();

  const getTreatmentColour = (treatment: string) => {
    const colour = JSON.parse(JSON.stringify(treatmentColourMap))[treatment];

    return colour;
  };

  // Manage the popup on clicking the text "Contact us for more details"
  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };
  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  //get all facilities by city and treatment id
  const getDoctorByFacility = async (
    payload: {
      facilityId: string;
    },
    isInitialLoad: boolean = true
  ) => {
    const { data, error } = await CureValueService(getById, payload);

    if (data) {
      totalDoctorRecords.current = data.totalCount;
      if (isInitialLoad) {
        setAllDoctors([...data.items]);
        setDoctorCount(data.totalCount);
      } else {
        setAllDoctors([...allDoctors, ...data.items]);
        setDoctorCount(data.totalCount);
      }
      // destinationSliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    // if(!facility)return;
    getDoctorByFacility({
      facilityId: facility._id,
    });
  }, []);

  useEffect(() => {
    if (isOpenModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpenModal]);

  const toggleModal = (e: any) => {
    e.preventDefault();
    setIsOpenModal(!isOpenModal);
  };

  const sliderRef = useRef<any>(null);
  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };
  var settings = {
    arrows: true,
    dots: false,
    infinite: allDoctors.length > 1,
    speed: 500,
    slidesToShow: allDoctors.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    prevArrow: <FaRegArrowAltCircleLeft />,
    nextArrow: <FaRegArrowAltCircleRight />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const handleLockBtnClick = () => {
    handleShowSignUpPopup();
  };

  console.log(allDoctors)

  return (
    <div id="careteam">
      <div className="mb-[30px] lg:mb-[52px] pb-[27px] lg:pb-[58px] border-b border-borderclr">
        <div className="flex items-center gap-[5px] pb-[16px]">
          <FaUserDoctor className="text-iconclr text-h3font lg:text-[30px]" />
          <h2 className="text-sc text-h3font font-semibold lg:text-[20px] lg:font-bold">
            Doctors
          </h2>
        </div>

        {(
          <>
            <div>
              {allDoctors.length != 0 && (
                <div className="careteam-slide">
                  <Slider {...settings} ref={sliderRef}>
                    {allDoctors?.map((doctor: any) => {
                      return (
                        <>
                          <div key={doctor._id}>
                            <div className="care-card border border-borderclr rounded-[30px] bg-white relative pt-[50px] lg:pt-[130px] mb-[30px] lg:mb-[50px] careteam-slide cursor-pointer hover:shadow-cardshadow">
                              {/* <img
                                src={doctor.profile_image}
                                alt=""
                                className="pb-[10px] max-w-[133px] h-[230px] lg:max-w-[175px] max-h-[175px] rounded-[30px] absolute left-[30px] right-0 top-[-60px] object-contain bg-gray-500 aspect-[16/9]"
                              /> */}
                              {/* Replacing the doctor image with default icon from react icons */}
                              {doctor.profile_image ? (
                                <img
                                    src={doctor.profile_image}
                                    alt=""
                                    className="pb-[10px] max-w-[133px] h-[230px] lg:max-w-[175px] max-h-[175px] rounded-[30px] absolute left-[30px] right-0 top-[-60px] object-contain bg-gray-500 aspect-[16/9]"
                                  />
                                ) : (
                                  <FaUserDoctor className="text-[133px] h-[230px] lg:text-[175px] max-h-[175px] rounded-[30px] absolute left-[30px] right-0 top-[-60px] text-gray-500" />
                                )}
                              <div className="p-[30px] pt-[70px] lg:max-w-[95%] lg:pt-[20px]">
                                <h2 className="text-extraclr3 text-[20px] lg:text-[19px] font-semibold pb-[8px]">
                                  {doctor.doctor_name}
                                </h2>

                                <div className="flex flex-wrap items-center gap-[10px] pb-[14px]">
                                  {doctor.treatments_offered.map(
                                    (treatment: any) => {
                                      return (
                                        <>
                                          {/* <p className="text-smalltext font-medium px-[8px] py-[2px] bg-[#005fdb24] text-blueclr2">
                                  {treatment.name}
                                </p> */}

                                          <p
                                            className={`text-[10px] me-4 xl:text-anchortext font-medium px-[8px] py-[2px] ${getTreatmentColour(
                                              treatment.name
                                            )}`}
                                            key={Math.random()}
                                          >
                                            {treatment.name}
                                          </p>
                                        </>
                                      );
                                    }
                                  )}
                                </div>

                                <div className="pb-[20px]">
                                  <h6 className="capitalize text-anchortext lg:text-smalltext font-semibold text-cardpara">
                                    gender
                                  </h6>
                                  <p className="capitalize text-smalltext">
                                    {doctor.gender}
                                  </p>
                                </div>
                                <div className="pb-[20px]">
                                  <h6 className="capitalize text-anchortext lg:text-smalltext font-semibold text-cardpara">
                                    Education & Training
                                  </h6>
                                  <p className="text-smalltext">
                                    {doctor.education}
                                  </p>
                                </div>
                                <div className="pb-[20px]">
                                  <h6 className="capitalize text-anchortext lg:text-smalltext font-semibold text-cardpara">
                                    Board Certifications
                                  </h6>
                                  <p className="text-smalltext">
                                    {doctor.certifications}
                                  </p>
                                </div>

                                <a
                                  href="#"
                                  className="text-pfont lg:text-smalltext  font-medium text-[#006AF5] underline underline-offset-2"
                                  onClick={(e) => {
                                    setSelectedDoctor(doctor);
                                    toggleModal(e);
                                  }}
                                >
                                  View Profile
                                </a>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </Slider>

                  <div className="slider-arrows flex items-center gap-[25px] lg:gap-[45px] pl-[20px] justify-start">
                    <div className="prev-arrow" onClick={handlePrevClick}>
                      <FaRegArrowAltCircleLeft className="text-extraclr1 text-[38px] lg:text-[50px] cursor-pointer" />
                    </div>
                    <div className="next-arrow" onClick={handleNextClick}>
                      <FaRegArrowAltCircleRight className="text-extraclr1 text-[38px] lg:text-[50px] cursor-pointer" />
                    </div>
                  </div>
                </div>
              )}
              {allDoctors.length == 0 && (
                <p className="text-pfont text-iconclr font-medium">
                  <button onClick={handlePopupOpen}>Contact us for more details</button>
                </p>
              )}
              {/* Conditionally render the popup */}
              {isPopupOpen && (
                // <CVPopup onClose={handlePopupClose} isClosable={true}>
                //   <p>Here are the contact details you requested!</p>
                // </CVPopup>
                <CVPopup
                onClose={() => {
                  handlePopupClose();
                }}
                isClosable
              >
                <AppointmentSchedule
                  treatmentId={selectedTreatment}
                  facility={facility}
                  onClose={() => {
                    handlePopupClose();
                  }}
                ></AppointmentSchedule>
                </CVPopup>
              )}
            </div>
          </>
        )}
      </div>

      {isOpenModal && (
        <CVPopup
          isClosable={true}
          onClose={() => {
            setIsOpenModal(false);
          }}
        >
          <div className="flex flex-col lg:flex-row lg:items-end gap-[30px] lg:gap-[60px] pb-[20px] lg:pb-[35px]">
            <div>
              <img src={selectedDoctor?.profile_image} alt="" />
            </div>
            <div>
              <h2 className="text-[24px] text-extraclr3 font-semibold pb-[3px]">
                {selectedDoctor?.doctor_name}
              </h2>
              <div className="flex items-center gap-[10px] pb-[21px]">
                {selectedDoctor?.treatments_offered.map((treatment: any) => {
                  return (
                    <>
                      <p className="text-smalltext lg:text-anchortext font-medium px-[8px] py-[2px] bg-[#005fdb24] text-blueclr2">
                        {treatment.name}
                      </p>
                    </>
                  );
                })}

                {/* <p className="text-smalltext lg:text-anchortext font-medium px-[8px] py-[2px] bg-[#1584441c] text-extraclr4">
                      Cardiology
                    </p>
                    <p className="text-smalltext lg:text-anchortext font-medium px-[8px] py-[2px] bg-[#98521138] text-extraclr5">
                      Dentistry
                    </p> */}
              </div>
              <div>
                <h6 className="capitalize text-anchortext lg:text-pfont font-semibold text-cardpara">
                  gender
                </h6>
                <p className="capitalize"> {selectedDoctor?.gender}</p>
              </div>
            </div>
          </div>

          <div className="lg:max-w-[90%] modal-hospital">
            <h3 className="text-[20px] font-semibold text-extraclr1 mb-[22px]">
              About the Doctor
            </h3>
            <p className="mb-[22px] text-anchortext">
              We apologize, but detailed information for this doctor is
              currently unavailable.
            </p>

            <div className="grid lg:grid-cols-3 items-start gap-[20px] lg:gap-[40px]">
              <div>
                <h6 className="capitalize text-anchortext font-semibold text-cardpara">
                  Languages Spoken
                </h6>
                <p className="text-anchortext">English (fluent)</p>
              </div>
              <div>
                <h6 className="capitalize text-anchortext font-semibold text-cardpara">
                  Education & Training
                </h6>
                <p className="text-anchortext">{selectedDoctor?.education}</p>
              </div>
              <div>
                <h6 className="capitalize text-anchortext font-semibold text-cardpara">
                  Board Certifications
                </h6>
                <p className="text-anchortext">
                  {selectedDoctor?.certifications}
                </p>
              </div>
              <div>
                <h6 className="capitalize text-anchortext font-semibold text-cardpara">
                  Residencies
                </h6>
                <p className="text-anchortext">...</p>
              </div>
              <div>
                <h6 className="capitalize text-anchortext font-semibold text-cardpara">
                  Medical Schools
                </h6>
                <p className="text-anchortext">...</p>
              </div>
            </div>
          </div>
        </CVPopup>
      )}
    </div>
  );
};

export default CareTeam;
